import React from 'react';
import { Helmet } from 'react-helmet-async';
import cx from 'classnames';

import { Header, HeaderProps } from './Header';
import { Footer, FooterProps } from './Footer';
import { Button, ButtonProps } from '../../UI/Button';
import { Modal, ModalProps } from '../../UI/Modal';
import { Loader } from '../../UI/Loader';

import './ApplicationPage.scss';

export interface ApplicationPageProps extends HeaderProps, FooterProps {
  loading?: boolean;
  metaTitleTemplate: string;
  metaTitle: string;
  audioVolumeIsDisplayed?: boolean;
  hasNextStepBlock?: boolean;
  nextStepButtonProps?: Omit<ButtonProps, 'children'> & { label: string };
  modalWarningProps?: ModalProps & {
    resumeButtonLabel: React.ReactNode;
    resetButtonLabel: React.ReactNode;
    resumeButtonIsLoading?: boolean;
    onResetTest: () => void;
  };
}

export const ApplicationPage: React.FC<ApplicationPageProps> = ({
  loading = false,
  audioVolumeIsDisplayed = false,
  metaTitleTemplate,
  metaTitle,
  currentLanguageLabel,
  languageItemLinks,
  audioVolume,
  onAudioVolumeChange,
  legalNoticeText,
  termsAndConditionsText,
  editCookiesSettingsText,
  copyrightText,
  hasCookieConsent,
  hasNextStepBlock = false,
  nextStepButtonProps,
  modalWarningProps,
  children,
}) => {
  return (
    <>
      <Helmet titleTemplate={metaTitleTemplate} title={metaTitle} />
      <div className={cx('application-page', { 'application-page--with-next-step': hasNextStepBlock })}>
        <Header
          currentLanguageLabel={currentLanguageLabel}
          languageItemLinks={languageItemLinks}
          audioVolumeIsDisplayed={audioVolumeIsDisplayed}
          audioVolume={audioVolume}
          onAudioVolumeChange={onAudioVolumeChange}
        />
        <main className="application-page__content">{loading ? <Loader /> : children}</main>
        {hasNextStepBlock && (
          <div className="application-page__next-step-block">
            <Button
              size="large"
              bold={true}
              uppercase={true}
              className="application-page__next-step-button"
              data-test="nextStepButton"
              {...nextStepButtonProps}
            >
              {nextStepButtonProps.label}
              <div className="arrow-right" />
            </Button>
          </div>
        )}
        <Footer
          legalNoticeText={legalNoticeText}
          termsAndConditionsText={termsAndConditionsText}
          editCookiesSettingsText={editCookiesSettingsText}
          copyrightText={copyrightText}
          hasCookieConsent={hasCookieConsent}
        />
      </div>
      {modalWarningProps && (
        <Modal
          isOpen={modalWarningProps.isOpen}
          onCloseModal={modalWarningProps.onCloseModal}
          title={modalWarningProps.title}
          content={modalWarningProps.content}
        >
          <div className="application-page__modal-buttons-container">
            <Button
              size="large"
              uppercase
              bold
              color="white"
              onClick={modalWarningProps.onCloseModal}
              loading={modalWarningProps?.resumeButtonIsLoading}
              data-test={'resumeModalButton'}
            >
              {modalWarningProps.resumeButtonLabel}
            </Button>
            <Button size="large" loading={loading} uppercase bold onClick={modalWarningProps.onResetTest}>
              {modalWarningProps.resetButtonLabel}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
