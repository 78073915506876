import React, { useEffect, useMemo } from 'react';
import { generatePath, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { DEFAULT_LANGUAGE, LANGUAGES } from '../constants';
import { paths } from '../paths';
import { useAppContext } from '../contexts/app';

export interface UseLanguage {
  currentLanguage: {
    translationKey: string;
    value: string;
  };
  languageItemLinks: React.ReactNode[];
}

export const useLanguage = (): UseLanguage => {
  const { lang = DEFAULT_LANGUAGE } = useParams<{ lang: string }>();
  const { i18n, t } = useTranslation();
  const { currentRoute } = useAppContext();

  useEffect(() => {
    if (i18n?.language?.toLowerCase() !== lang.toLowerCase()) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  const currentLanguage = useMemo(() => {
    return LANGUAGES.find((language) => language.value === lang);
  }, [lang]);

  const languageItemLinks = useMemo(() => {
    return LANGUAGES.map((language, index) => {
      const routePattern = currentRoute?.path;
      const path =
        !routePattern || routePattern === paths.SESSION_TOKEN || routePattern === paths.ERROR
          ? `/${language.value}`
          : generatePath(routePattern as string, { lang: language.value });

      return (
        <Link key={index} to={path} className="select-language__list-item" data-test="linkToChangeLang">
          {t(language.translationKey)}
        </Link>
      );
    });
  }, [t, currentRoute?.path]);

  return {
    currentLanguage,
    languageItemLinks,
  };
};
